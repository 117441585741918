import React, { useState, useEffect, useRef } from "react";
import { fetchUserChats, askQuestion, getJwtToken } from "../actions/actions";

const Dashboard = () => {
  const [conversations, setConversations] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);
  const [chatError, setChatError] = useState("");
  const chatEndRef = useRef(null);

  useEffect(() => {
    if (!getJwtToken()) {
      window.location.href = "/login";
    }

    fetchChatData();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [conversations]);

  const fetchChatData = async () => {
    setChatError("");

    try {
      const response = await fetchUserChats();

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const { user_conversation } = data;

      if (user_conversation && user_conversation.length > 0) {
        setConversations(user_conversation);
      }
    } catch (error) {
      console.error("Error fetching chat data:", error);
      setChatError(
        error.message || "Failed to fetch chat data. Please try again.",
      );
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    setSendingMessage(true);
    setChatError("");

    try {
      const response = await askQuestion(newMessage);

      if (!response.ok) {
        throw new Error(
          `Failed to send message. Please try again: ${response.status}`,
        );
      }

      const responseData = await response.json();

      const updatedConversations = [
        ...conversations,
        {
          user_query: newMessage,
          ai_response: responseData.message,
          timestamp: new Date().toISOString(),
        },
      ];

      setConversations(updatedConversations);
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
      setChatError(
        error.message || "Failed to send message. Please try again.",
      );
    } finally {
      setSendingMessage(false);
    }
  };

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "h-full",
        backgroundColor: "#708914",
      }}
    >
      <div
        style={{
          backgroundColor: "#1A202C",
          padding: "40px 40px 15px 40px",
          borderRadius: "8px",
          maxWidth: "1200px",
          width: "100%",
          overflow: "scroll",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "300px",
            height: "300px",
            maxHeight: "300px",
            overflow: "auto",
            backgroundColor: "#2D3748",
            padding: "20px",
            borderRadius: "8px",
            marginBottom: "20px",
            minHeight: "70%x",
            marginLeft: "auto",
          }}
        >
          {conversations?.length > 0 ? (
            conversations?.map((msg, index) => (
              <div key={index} style={{ marginBottom: "10px" }}>
                <div
                  style={{
                    backgroundColor: "#556B2F",
                    color: "white",
                    padding: "10px",
                    borderRadius: "8px",
                    textAlign: "end",
                    alignSelf: "right",
                    width: "fit-content",
                    marginLeft: "auto",
                  }}
                >
                  <p style={{ marginBottom: "0px" }}>{msg.user_query}</p>
                </div>
                {msg.ai_response && (
                  <div
                    style={{
                      backgroundColor: "#4A5568",
                      color: "#CBD5E0",
                      padding: "10px",
                      borderRadius: "8px",
                      marginTop: "10px",
                      alignSelf: "flex-start",
                    }}
                  >
                    <p style={{ marginBottom: "0px" }}>{msg.ai_response}</p>
                  </div>
                )}
                <p
                  style={{
                    fontSize: "12px",
                    color: "#A0AEC0",
                    marginTop: "5px",
                  }}
                >
                  {msg.timestamp}
                </p>
              </div>
            ))
          ) : (
            <p style={{ color: "#E2E8F0" }}>
              No conversation history exists...
            </p>
          )}
          <div ref={chatEndRef} />
        </div>

        <p className="text-white text-center" style={{ fontSize: "12px" }}>
          {" "}
          *All responses are based on either the Constitution of the Federal Republic
          of Nigeria 1999 with the 1st, 2nd, 3rd alterations (2010), 4th
          alteration (2017) and 5th alteration (2023){" "} or Nigerian Police Act of 2020.
        </p>

        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            type="text"
            placeholder="Ask a question..."
            style={{
              flexGrow: 1,
              padding: "10px",
              borderRadius: "4px 0 0 4px",
              outline: "none",
            }}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !sendingMessage) {
                handleSendMessage();
              }
            }}
          />
          <button
            style={{
              backgroundColor: "#556B2F",
              color: "#FFFFFF",
              padding: "10px 20px",
              borderRadius: "0 4px 4px 0",
              marginLeft: "10px",
              cursor: "pointer",
              border: "none",
            }}
            onClick={handleSendMessage}
            disabled={sendingMessage}
          >
            {sendingMessage ? "Processing..." : "Send"}
          </button>
        </div>

        <p className="text-white text-center mt-4">
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => window.open('/Constitution.pdf', '_blank')}
          >
            View Constitution
          </span>
          {" | "}
          <span
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => window.open("./NigerianPoliceAct2020.pdf", '_blank')}
          >
            View Police Act
          </span>
        </p>

        {chatError && (
          <p style={{ color: "#E53E3E", marginTop: "5px" }}>{chatError}</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
