import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logoImage from "../components/images/logo.png";
import { getJwtToken } from "../actions/actions";

const LandingPage = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    if (getJwtToken()) {
      navigate("/user/dashboard/poll");
    } else {
      navigate("/login");
    }
  };

  return (
    <Container className="my-5 d-flex flex-column align-items-center">
      <Row className="justify-content-center">
        <Col xs={12} className="d-flex justify-content-center">
          <img
            src={logoImage}
            alt="Logo"
            className="login-logo"
            onClick={handleRedirect}
          />
        </Col>
      </Row>
      <Row className="justify-content-center mt-4">
        <Col xs={12} className="d-flex justify-content-center">
          <h2
            className="text-center"
            style={{ cursor: "pointer" }}
            onClick={handleRedirect}
          >
            Enter
          </h2>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPage;
