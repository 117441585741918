import React, { useState, useEffect } from "react";
import { Form, Alert } from "react-bootstrap";
import { signup, googleSSOAuth, facebookSSOAuth } from "../actions/actions";
import statesData from "./statesData";
import { useNavigate } from "react-router-dom";
import { App as CapacitorApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";

const SignupForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nin, setNin] = useState("");
  const [twoFactorCode, setTwoFactorCode] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showTwoFactorInput, setShowTwoFactorInput] = useState(false);
  const [message, setMessage] = useState(null);

  const [state, setState] = useState("");
  const [stateId, setStateId] = useState("");
  const [lga, setLga] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    CapacitorApp.addListener("appUrlOpen", (data) => {
      if (data.url) {
        const url = data.url;

        if (url.includes("redirection")) {
          const startIndex = url.indexOf("redirection");
          const redirectionRoute = url.substring(startIndex);

          navigate(`/${redirectionRoute}`);
        }
      }
    });
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();

    signup(name, email, password, phoneNumber, nin, twoFactorCode)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((responseData) => {
            window.location.href = responseData?.redirect || "/login";
            setMessage({
              type: "success",
              text: "Please verify your account to complete signup",
            });
          });
        } else {
          response.json().then((responseData) => {
            if (responseData?.message.includes("Two factor")) {
              setMessage({
                type: "info",
                text: responseData?.message,
              });
              setShowTwoFactorInput(true);
            } else if (
              responseData?.message.includes("User created successfully")
            ) {
              setMessage({
                type: "success",
                text: responseData?.message || "Signup successful",
              });
              navigate("/login");
            } else {
              setMessage({
                type: "info",
                text: responseData?.message || "Signup failed",
              });
            }
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage({
          type: "danger",
          text: "Some error occurred during signup",
        });
      });
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const states = statesData;

  const handleStateChange = (selectedState) => {
    setState(selectedState);
    const selectedStateInfo = states.find((s) => s.name === selectedState);
    if (selectedStateInfo) {
      setStateId(selectedStateInfo.id);
      setLga("");
    }
  };

  const handleGoogleSignup = () => {
    if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
      const isBrowser = !Capacitor.isNativePlatform();

      if (isBrowser) {
        localStorage.setItem("is_browser_originated_request", "true");
      }
    }

    googleSSOAuth()
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.auth_url) {
          window.location.href = data.auth_url;
        } else {
          setMessage({
            type: "danger",
            text: "Some error occurred during signup",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage({
          type: "danger",
          text: "Some error occurred during signup",
        });
      });
  };

  const handleFacebookSignup = () => {
    if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
      const isBrowser = !Capacitor.isNativePlatform();

      if (isBrowser) {
        localStorage.setItem("is_browser_originated_request", "true");
      }
    }

    facebookSSOAuth()
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.auth_url) {
          window.location.href = data.auth_url;
        } else {
          setMessage({
            type: "danger",
            text: "Some error occurred during signup",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setMessage({
          type: "danger",
          text: "Some error occurred during signup",
        });
      });
  };

  return (
    <>
      {message && (
        <Alert
          variant={message.type}
          onClose={() => setMessage(null)}
          dismissible
        >
          {message.text}
        </Alert>
      )}

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name*</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ marginBottom: "20px" }}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email address*</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ marginBottom: "20px" }}
            required
          />
        </Form.Group>

        <Form.Group controlId="formPassword">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your phone number"
            name="number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
        </Form.Group>

        <Form.Group controlId="formPassword">
          <Form.Label>NIN</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your NIN"
            name="nin"
            value={nin}
            onChange={(e) => setNin(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
        </Form.Group>

        {showTwoFactorInput && (
          <Form.Group controlId="formTwoFactorCode">
            <Form.Label>Two Factor Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter two factor code sent"
              name="twoFactorCode"
              value={twoFactorCode}
              onChange={(e) => setTwoFactorCode(e.target.value)}
              style={{ marginBottom: "20px" }}
              required
            />
          </Form.Group>
        )}

        <Form.Group controlId="formPassword">
          <Form.Label>Password*</Form.Label>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder="Enter your password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ marginBottom: "15px" }}
            required
          />
          <div className="form-check ml-2 mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="showPasswordCheckbox"
              onChange={handleTogglePassword}
            />
            <label className="form-check-label" htmlFor="showPasswordCheckbox">
              Show Password
            </label>
          </div>
        </Form.Group>

        <Form.Group controlId="formState">
          <Form.Label>State</Form.Label>
          <Form.Control
            as="select"
            value={state}
            onChange={(e) => handleStateChange(e.target.value)}
            required
            style={{ marginBottom: "15px" }}
          >
            <option value="">Select State</option>
            {states.map((s) => (
              <option key={s.name}>{s.name}</option>
            ))}
          </Form.Control>
        </Form.Group>

        {state && (
          <Form.Group controlId="formStateId">
            <Form.Label>State ID</Form.Label>
            <Form.Control
              type="text"
              value={stateId}
              style={{ marginBottom: "15px" }}
            />
          </Form.Group>
        )}

        {state && (
          <Form.Group controlId="formLga">
            <Form.Label>LGA</Form.Label>
            <Form.Control
              as="select"
              value={lga}
              onChange={(e) => setLga(e.target.value)}
              style={{ marginBottom: "15px" }}
              required
            >
              <option value="">Select LGA</option>
              {states
                .find((s) => s.name === state)
                ?.lgas.map((lga) => (
                  <option key={lga}>{lga}</option>
                ))}
            </Form.Control>
          </Form.Group>
        )}

        <button
          type="submit"
          className="w-100 mt-1"
          style={{
            padding: "8px",
            margin: "auto",
            backgroundColor: "#708914",
            borderRadius: "10px",
            color: "white",
            textAlign: "center",
            fontWeight: "bold",
            border: "none",
          }}
        >
          Sign Up
        </button>

        <div className="text-center my-3">
          <p>OR</p>
          <div
            className="d-flex w-100 justify-content-around text-white gap-2"
            style={{ margin: "0px" }}
          >
            <button
              style={{
                width: "50%",
                margin: "0px",
                padding: "8px",
                fontWeight: "bold",
                backgroundColor: "#708914",
                borderRadius: "10px",
                cursor: "pointer",
                border: "none",
                color: "white",
              }}
              onClick={handleGoogleSignup}
            >
              <i className="fab fa-google me-2" />
              Continue with Google
            </button>
            <button
              style={{
                width: "50%",
                margin: "0px",
                padding: "8px",
                fontWeight: "bold",
                backgroundColor: "#708914",
                borderRadius: "10px",
                cursor: "pointer",
                border: "none",
                color: "white",
              }}
              onClick={handleFacebookSignup}
            >
              <i className="fab fa-facebook me-2" />
              Continue with Facebook
            </button>
          </div>
        </div>

        <p className="text-center" style={{ color: "gray" }}>
          <i>
            By using our services, you confirm that you've read, understood, and
            agree to our{" "}
            <a href="/privacy-policy" style={{ color: "#708914" }}>
              Privacy Policy
            </a>
            .
          </i>
        </p>
      </Form>
    </>
  );
};

export default SignupForm;
